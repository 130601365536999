import {strings} from '../../../services/language';
import styles from './StaffDetails.module.scss';
import {editProfileAPI, useAPI} from '../../../services/api';
import {useEffect, useMemo, useRef, useState} from 'react';
import {Column, Row} from 'react-table';
import {ReactComponent as TrashIcon} from '../../../assets/img/trash.svg';
import {validateEmail} from '../../../services/utils';
import {useInput, useSelect, useSyncCentres} from '../../../services/hooks';
import {Centre, Profile} from '../../../services/models';

interface CentreData {
  index: JSX.Element;
  id: number;
  centre: string;
  district: string;
  button: JSX.Element;
}

interface AllCentresData {
  id: number;
  centre: string;
  district: string;
}

export function useStaffDetailsLogic(staffProfile: Profile) {
  const [searchValue, setSearchValue] = useState('');
  const [centreList, setCentreList] = useState<Row<object>[]>([]);
  const [centreError, setCentreError] = useState('');
  const [resignedAt, setResignedAt] = useState<Date>();
  const [selectingCentres, setSelectingCentres] = useState<Row<object>[]>([]);
  const [recordNumber, setRecordNumber] = useState(0);
  const [selectFilters, setSelectFilters] = useState<{
    [key: string]: (string | number)[];
  }>({district: []});
  const [isSetCentre, setIsSetCentre] = useState(false);
  const [centreRows, setCentreRows] = useState<Row<object>[]>([]);
  const [detectChange, setDetectChange] = useState({
    name: false,
    email: false,
    phone: false,
    role: false,
    centres: false,
    resignedAt: false,
  });
  const {states: staffName, bind: bindStaffName} = useInput(staffProfile.name);
  const {states: staffResignedAt, bind: bindStaffResignedAt} = useSelect(
    new Date(staffProfile?.resignedAt || '')
  );
  const {states: staffPhone, bind: bindStaffPhone} = useInput(
    staffProfile.phone
  );
  const {states: staffEmail, bind: bindStaffEmail} = useInput(
    staffProfile.email
  );
  const {states: staffRole, bind: bindStaffRole} = useSelect(staffProfile.role);

  const {centresApi, centres} = useSyncCentres();
  const editProfileApi = useAPI(() => {
    const centreIdList = centreList.map(row => row.values.id);

    return editProfileAPI({
      id: staffProfile.id,
      name: staffName.value,
      ...(staffEmail.value && staffEmail.changed && {email: staffEmail.value}),
      role: staffRole.value,
      ...(staffPhone.value && staffPhone.changed && {phone: staffPhone.value}),
      ...(staffResignedAt.value &&
        staffResignedAt.changed && {resignedAt: staffResignedAt.value}),
      ...(centreIdList.length > 0 && {centreIds: centreIdList}),
    });
  });

  const selectedCentresColumns: Column[] = useMemo(
    () => createCentreColumns(),
    []
  );
  const selectedCentresData: CentreData[] = useMemo(
    () => createCentreData(centreList, setCentreList, staffProfile),
    [centreList]
  );

  const allCentresColumns: Column[] = useMemo(
    () => createAllCentresColumns(),
    []
  );
  const allCentresData: AllCentresData[] = useMemo(
    () => createAllCentresData(centres),
    [centres]
  );

  useEffect(() => {
    if (
      staffProfile.centreIds &&
      staffProfile.centreIds.length > 0 &&
      centreRows.length > 0 &&
      !isSetCentre
    ) {
      const selected: Row<object>[] = [];
      centreRows.forEach(row => {
        if (staffProfile.centreIds?.includes(row.values.id)) {
          row.toggleRowSelected(true);
          selected.push(row);
        }
      });
      setCentreList(selected);
      setIsSetCentre(true);
    }
  }, [staffProfile.centreIds, centreRows]);

  useEffect(() => {
    let obj = {};
    const centreIds = staffProfile.centreIds || [];
    const centreIdList = centreList.map(row => row.values.id);
    const isIncluded = centreIds.every(id => centreIdList.includes(id));

    obj = staffName.changed ? {...obj, name: true} : {...obj, name: false};
    obj = staffEmail.changed ? {...obj, email: true} : {...obj, email: false};
    obj = staffPhone.changed ? {...obj, phone: true} : {...obj, phone: false};
    obj = staffRole.changed ? {...obj, role: true} : {...obj, role: false};
    obj = staffResignedAt.changed
      ? {...obj, resignedAt: true}
      : {...obj, resignedAt: false};

    if (centreIds.length === centreIdList.length && isIncluded) {
      obj = {...obj, centres: false};
    } else {
      obj = {...obj, centres: true};
    }

    setDetectChange({...detectChange, ...obj});
    validate();
  }, [
    staffName.value,
    staffEmail.value,
    staffPhone.value,
    staffRole.value,
    staffResignedAt.value,
    centreList,
  ]);

  const editCentreModalRef = useRef<any>(null);
  const resultModalRef = useRef<any>(null);

  const validate = () => {
    staffRole.value === ''
      ? staffRole.setError(strings('Please select a role'))
      : staffRole.setError('');

    staffName.value === ''
      ? staffName.setError(strings('Please enter a name'))
      : staffName.setError('');

    staffEmail.value === '' && staffPhone.value === ''
      ? (staffEmail.setError(strings('Please enter a valid email')),
        staffPhone.setError(strings('Please enter a valid number')))
      : (staffEmail.setError(''), staffPhone.setError(''));

    staffEmail.value !== ''
      ? validateEmail(staffEmail.value)
        ? staffEmail.setError('')
        : staffEmail.setError('Please enter a valid email')
      : null;

    ['', 'HR'].includes(staffRole.value)
      ? setCentreError('')
      : centreList.length === 0
      ? setCentreError(strings('Please select a centre'))
      : centreList.length > 1 && ['VP', 'LT', 'ST'].includes(staffRole.value)
      ? setCentreError(strings('Please select only 1 centre'))
      : setCentreError('');

    return (
      !!staffRole.value &&
      !!staffName.value &&
      ((!!staffEmail.value && validateEmail(staffEmail.value)) ||
        (!!staffPhone.value && !staffEmail.value) ||
        (!!staffPhone.value &&
          !!staffEmail.value &&
          validateEmail(staffEmail.value))) &&
      (['HR', 'CP'].includes(staffRole.value) ||
        (centreList.length === 1 &&
          ['VP', 'LT', 'ST'].includes(staffRole.value)) ||
        (centreList.length >= 1 && ['EP', 'EPA'].includes(staffRole.value)))
    );
  };

  const onSubmit = () => {
    const formValid = validate();
    formValid && editProfileApi.request();
  };

  return {
    centresApi,
    searchValue,
    setSearchValue,
    setResignedAt,
    bindStaffResignedAt,
    staffResignedAt,
    resignedAt,
    staffName,
    bindStaffName,
    staffPhone,
    bindStaffPhone,
    staffEmail,
    bindStaffEmail,
    staffRole,
    bindStaffRole,
    centreList,
    setCentreList,
    selectedCentresColumns,
    selectedCentresData,
    editCentreModalRef,
    resultModalRef,
    centreError,
    recordNumber,
    setRecordNumber,
    selectingCentres,
    setSelectingCentres,
    allCentresColumns,
    allCentresData,
    onSubmit,
    editProfileApi,
    setCentreRows,
    detectChange,
    selectFilters,
    setSelectFilters,
    isSetCentre,
  };
}

function createCentreColumns() {
  return [
    {
      Header: '',
      accessor: 'index',
    },
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: strings('Centre'),
      accessor: 'centre',
    },
    {
      Header: strings('District'),
      accessor: 'district',
    },
    {
      Header: '',
      accessor: 'button',
    },
  ];
}

function createCentreData(
  centreList: Row<object>[],
  setCentreList: React.Dispatch<React.SetStateAction<any[]>>,
  staffProfile: Profile
): CentreData[] {
  return centreList.map((centre, index, centreList) => ({
    index: <span className={styles.greyText}>{index + 1}</span>,
    id: centre.values.id,
    centre: centre.values.centre,
    district: centre.values.district,
    button: (
      <div
        className={styles.buttonCell}
        onClick={() => {
          setCentreList(centreList.filter((item, i) => i !== index));
          centre.toggleRowSelected();
        }}
      >
        {staffProfile.role !== 'CP' && <TrashIcon />}
      </div>
    ),
  }));
}

function createAllCentresColumns() {
  return [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: strings('Centre'),
      accessor: 'centre',
    },
    {
      Header: strings('District'),
      accessor: 'district',
      filter: 'selectOptions',
    },
  ];
}

function createAllCentresData(allCentres: Centre[]): AllCentresData[] {
  return allCentres.map((centre, index) => ({
    id: centre.id,
    centre: centre.name,
    district: centre.districtGroup,
  }));
}
